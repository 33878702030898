import React, { Suspense, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ChakraProvider, Stack } from '@contact/ui-volta';
import {
  ContactEnergyTheme,
  Footer,
  GlobalHeader,
  Loading,
} from '@contact/ui-components';
import {
  AlertProvider,
  ConfigProvider,
  TranslationProviderWithResources,
} from '@contact/react';
import {
  AccountSettingsRedirect,
  ForgotPasswordPage,
  ForgotPasswordSuccessPage,
  RegisterPage,
  ResetPasswordPage,
  ResetPasswordSuccessPage,
  SignInPage,
  AccountActivationPage,
} from '@contact/ui-auth';
import { injectGtm } from '../Utils/gtm';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

export const App = () => {
  useEffect(() => {
    injectGtm(process.env.NX_GTM_ID);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={ContactEnergyTheme}>
        <Suspense fallback={<Loading />}>
          <ConfigProvider
            value={{
              i18next: { lng: 'en-NZ' },
            }}
          >
            <TranslationProviderWithResources
              resources={{
                'en-NZ': {
                  GlobalBrandedNavigation: {
                    href: `${process.env.NX_WEBSITE_BASE_URL}`,
                  },
                },
              }}
            >
              <AlertProvider>
                <Stack
                  style={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <GlobalHeader />
                  <Route path="/sign-in" exact component={SignInPage} />
                  <Route path="/register" exact component={RegisterPage} />
                  <Route
                    path="/account-activation"
                    exact
                    component={AccountActivationPage}
                  />
                  <Route
                    path="/forgot-password"
                    exact
                    component={ForgotPasswordPage}
                  />
                  <Route
                    path="/forgot-password-email-sent"
                    exact
                    component={ForgotPasswordSuccessPage}
                  />
                  <Route
                    path="/reset-password"
                    exact
                    component={ResetPasswordPage}
                  />
                  <Route
                    path="/reset-password-success"
                    exact
                    component={ResetPasswordSuccessPage}
                  />
                  {/* A workaround for SAP to point to this single URL for both reset password & account activation links */}
                  <Route
                    path="/account-settings"
                    exact
                    component={AccountSettingsRedirect}
                  />
                  <Footer />
                </Stack>
              </AlertProvider>
            </TranslationProviderWithResources>
          </ConfigProvider>
        </Suspense>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
