import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useForgotPassword } from '@contact/data-access-hooks';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text,
} from '@contact/ui-volta';
import { Alerts, AutoCompleteEmail, Loading } from '@contact/ui-components';
import { useAlertActions } from '@contact/react';
import { Constants } from '../Constants';
import { useContext, useNavigate } from '../Hooks';

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [addAlert, removeAlert] = useAlertActions();
  const context = useContext();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageView',
      virtualPagePath: window.location.pathname,
      virtualPageTitle: 'Forgotten password page',
    });
  }, []);

  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<{
    username: string;
  }>({
    defaultValues: {
      username: '',
    },
  });

  const {
    mutate: forgotPassword,
    isLoading: isForgotPasswordProcessing,
    isSuccess: isForgotPasswordSuccess,
    isError: isForgotPasswordError,
  } = useForgotPassword('v2', undefined, {
    onSuccess: () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'password_reset',
        from: context.app === 'ChangePlan' ? 'change plan' : 'my account',
      });
    },
  });

  const submitHandler = useCallback(
    (data: { username: string }) => forgotPassword(data),
    [forgotPassword]
  );

  const handleError = useCallback(
    (error: boolean) => {
      const value = getValues('username');
      if (error) {
        setError(
          'username',
          value
            ? {
                type: 'pattern',
                message: Constants.EmailInvalidError,
              }
            : {
                type: 'required',
                message: Constants.EmailRequiredError,
              },
          { shouldFocus: true }
        );
      } else {
        clearErrors('username');
      }
    },
    [clearErrors, getValues, setError]
  );

  useEffect(() => {
    if (isForgotPasswordSuccess) {
      navigate('/forgot-password-email-sent');
    }
  }, [isForgotPasswordSuccess, navigate]);

  useEffect(() => {
    if (isForgotPasswordError) {
      addAlert(
        {
          content: Constants.CommonError,
          icon: Constants.Alert.icon,
          styles: Constants.Alert.styles,
        },
        true
      );
    }
    return () => removeAlert(Constants.CommonError);
  }, [addAlert, isForgotPasswordError, removeAlert]);

  if (isForgotPasswordProcessing) {
    return <Loading />;
  }

  return (
    <Stack alignItems="stretch" spacing="80px" paddingX="24px" paddingY="64px">
      <Flex justifyContent="center">
        <Text
          fontSize={{
            base: '24px',
            sm: '32px',
          }}
        >
          {Constants.ResetPasswordTitle}
        </Text>
      </Flex>
      <Flex
        as="form"
        onSubmit={handleSubmit(submitHandler)}
        justifyContent="center"
      >
        <Stack flex={1} spacing="24px" maxWidth="360px">
          <FormControl id="username" isRequired isInvalid={!!errors.username}>
            <FormLabel>{Constants.UsernameLabel}</FormLabel>
            <Controller
              control={control}
              name="username"
              render={({
                field: { onChange, value, name, ref },
                fieldState: { error },
              }) => (
                <AutoCompleteEmail
                  name={name}
                  handleChange={onChange}
                  value={value}
                  hasError={!!error}
                  handleError={handleError}
                  inputProps={{ ref }}
                  maxLength={50}
                  validationType="email"
                  showSuccessIcon
                />
              )}
            />
            {errors.username && (
              <FormErrorMessage>{errors.username.message}</FormErrorMessage>
            )}
          </FormControl>
          <Alerts />
          <Button
            variant="primary"
            type="submit"
            disabled={!!errors.username || !getValues('username')}
            width="100%"
          >
            {Constants.ResetPasswordButtonLabel}
          </Button>
        </Stack>
      </Flex>
      <Flex justifyContent="center">
        <Text
          fontSize={{
            base: '14px',
            sm: '16px',
          }}
          textAlign="center"
          maxWidth="720px"
        >
          {Constants.ResetPasswordDesc}
        </Text>
      </Flex>
    </Stack>
  );
};
