import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { LoginApiRequest, useLogin } from '@contact/data-access-hooks';
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@contact/ui-volta';
import { Alerts, Loading } from '@contact/ui-components';
import { useAlertActions } from '@contact/react';
import {
  App,
  Configs,
  CookieParametersRegex,
  removeUnallowedParameters,
} from '@contact/auth-common';
import { Constants } from '../Constants';
import { useContext } from '../Hooks';
import {
  getUrlParameters,
  joinArrayItemsWithCommaAndLastAnd,
  parameterizedString,
} from '../Utils';

const SHARED_AUTH_COOKIE_NAME = 'userAuth';
const SHARED_AUTH_COOKIE_PATH = '/';
const SHARED_AUTH_COOKIE_DOMAIN = 'contact.co.nz';
const SHARED_USER_DETAILS_NAME = 'userDetails';
const SHARED_USER_DETAILS_DOMAIN = 'contact.co.nz';

function getAuthCookieDomain() {
  if (window.location.hostname === 'localhost') {
    return 'localhost';
  }

  return SHARED_AUTH_COOKIE_DOMAIN;
}

function getUserDetailsCookieDomain() {
  if (window.location.hostname === 'localhost') {
    return 'localhost';
  }

  return SHARED_USER_DETAILS_DOMAIN;
}

function getCookieSecure() {
  return window.location.hostname !== 'localhost';
}

const setSharedAuthCookie = (token: string) =>
  Cookies.set(SHARED_AUTH_COOKIE_NAME, token, {
    path: SHARED_AUTH_COOKIE_PATH,
    domain: getAuthCookieDomain(),
    expires: new Date(new Date().getTime() + 60 * 1000),
    secure: getCookieSecure(),
  });

type UserDetailsProps = {
  token: string;
  bpId: string;
};

const setUserDetailsCookie = ({ token, bpId }: UserDetailsProps, app: App) => {
  const urlParams = getUrlParameters();
  const isBpSwitched = bpId !== urlParams?.bpId;
  const cookie = removeUnallowedParameters(
    {
      token,
      bpId,
      baId: isBpSwitched ? '' : urlParams?.baId,
      premiseId: isBpSwitched ? '' : urlParams?.premiseId,
      isCSR: urlParams?.isCSR,
    },
    app
  );
  Cookies.set(SHARED_USER_DETAILS_NAME, JSON.stringify(cookie), {
    domain: getUserDetailsCookieDomain(),
    expires: new Date(new Date().getTime() + 60 * 1000),
    secure: getCookieSecure(),
  });
};

const getRedirectUrlWithParams = (url: string, app: App) => {
  const urlParams = removeUnallowedParameters(getUrlParameters(), app);
  let result = url;
  for (const key in urlParams) {
    if (!CookieParametersRegex[app].test(key)) {
      result = `${result}${result.includes('?') ? '&' : '?'}${key}=${
        urlParams[key]
      }`;
    }
  }
  return result;
};

export const SignInPage = () => {
  const context = useContext();
  const [addAlert, removeAlert] = useAlertActions();

  const [isSegmentNotAllowed, setIsSegmentNotAllowed] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LoginApiRequest>();

  const {
    mutate: login,
    data,
    isLoading: isLoginProcessing,
    isSuccess: isLoginSuccess,
    isError: isLoginError,
  } = useLogin('v2');

  const submitHandler = useCallback((data: LoginApiRequest) => login(data), [
    login,
  ]);

  useEffect(() => {
    if (isLoginSuccess && data) {
      let redirectUrl = context.url;

      if (!context.allowedSegments.includes(data.segment)) {
        if (!Constants.RedirectToOLSForNotAllowedSegment) {
          setIsSegmentNotAllowed(true);
          return;
        }
        redirectUrl = Configs.OLS.url;
      }
      if (redirectUrl === Configs.ChangePlan.url) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'change_plan_login',
          login: 'successfulLogin',
        });
        setUserDetailsCookie(
          {
            bpId: data.bp,
            token: data.token,
          },
          context.app
        );
      } else {
        setSharedAuthCookie(data.token);
      }

      setIsRedirecting(true);
      window.location.href = getRedirectUrlWithParams(redirectUrl, context.app);
    }
  }, [context.allowedSegments, context.app, context.url, data, isLoginSuccess]);

  useEffect(() => {
    if (isLoginError) {
      reset();
      addAlert(
        {
          content: Constants.LoginError,
          icon: Constants.Alert.icon,
          styles: Constants.Alert.styles,
        },
        true
      );
    }
    return () => removeAlert(Constants.LoginError);
  }, [addAlert, isLoginError, removeAlert, reset]);

  useEffect(() => {
    const errorMessage = parameterizedString(
      Constants.SegmentNotAllowedError,
      Constants.AppNameMapping[context.app],
      joinArrayItemsWithCommaAndLastAnd(
        context.allowedSegments.map(
          (segment) => Constants.SegmentDescMapping[segment]
        )
      )
    );
    if (isSegmentNotAllowed) {
      addAlert(
        {
          content: errorMessage,
          icon: Constants.Alert.icon,
          styles: Constants.Alert.styles,
        },
        true
      );
    }
    return () => removeAlert(errorMessage);
  }, [
    addAlert,
    context.allowedSegments,
    context.app,
    isSegmentNotAllowed,
    removeAlert,
  ]);

  const handleForgotPasswordClick = useCallback(() => {
    window.location.href = `${process.env.NX_WEBSITE_BASE_URL}/account/forgotten-password`;
  }, []);

  const handleRegisterClick = useCallback(() => {
    window.location.href = `${process.env.NX_WEBSITE_BASE_URL}/account/register`;
  }, []);

  if (isLoginProcessing || isRedirecting) return <Loading />;

  return (
    <Stack alignItems="stretch" spacing="48px" paddingX="24px" paddingY="64px">
      <Flex justifyContent="center">
        <Text
          fontSize={{
            base: '24px',
            sm: '32px',
          }}
        >
          {Constants.SignInTitle}
        </Text>
      </Flex>
      <Flex
        as="form"
        onSubmit={handleSubmit(submitHandler)}
        justifyContent="center"
      >
        <Stack flex={1} spacing="80px" maxWidth="360px">
          <Stack alignItems="stretch" spacing="24px">
            <Stack spacing="16px">
              <FormControl
                id="username"
                isRequired
                isInvalid={!!errors.username}
              >
                <FormLabel>{Constants.UsernameLabel}</FormLabel>
                <Input
                  {...register('username', {
                    required: true,
                  })}
                />
                {errors.username && (
                  <FormErrorMessage>{errors.username.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                id="password"
                isRequired
                isInvalid={!!errors.password}
              >
                <FormLabel>{Constants.PasswordLabel}</FormLabel>
                <Input
                  type="password"
                  {...register('password', {
                    required: true,
                    minLength: {
                      value: 8,
                      message: Constants.PasswordLengthError,
                    },
                    maxLength: {
                      value: 40,
                      message: Constants.PasswordLengthError,
                    },
                  })}
                />
                {errors.password && (
                  <FormErrorMessage>{errors.password.message}</FormErrorMessage>
                )}
              </FormControl>
              <Flex justifyContent="flex-end">
                <Button variant="link" onClick={handleForgotPasswordClick}>
                  {Constants.ForgotPasswordLabel}
                </Button>
              </Flex>
            </Stack>
            <Alerts />
            <Button variant="primary" type="submit" width="100%">
              {Constants.SignInButtonLabel}
            </Button>
          </Stack>
          <Stack spacing="40px">
            <Divider color="grayAlpha.300" />
            <Stack spacing="24px">
              <Flex justifyContent="center">
                <Text
                  fontSize={{
                    base: '20px',
                    sm: '24px',
                  }}
                >
                  {Constants.RegisterTitle}
                </Text>
              </Flex>
              <Button
                variant="secondary"
                width="100%"
                id="register-button"
                onClick={handleRegisterClick}
              >
                {Constants.RegisterButtonLabel}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
};
