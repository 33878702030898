import React from 'react';
import { useRadio, UseRadioProps } from '@chakra-ui/react';
import SelectedIcon from './SelectedIcon';
import UnselectedIcon from './UnselectedIcon';
import { RadioProps } from './RadioGroupTypes';
import classNames from 'classnames';

type CustomRadioProps = React.PropsWithChildren<UseRadioProps> & RadioProps;

function CustomRadio({
  labelClassName,
  ...props
}: CustomRadioProps): JSX.Element {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkboxProps = getCheckboxProps();
  const color = props.isInvalid ? '#f44336' : undefined;

  return (
    <label className="uiComponentsRadioGroup_radioContainer">
      <input
        {...input}
        style={{ display: 'none' }}
        role="radio"
        aria-checked={props.isChecked}
      />
      <div
        {...checkboxProps}
        className="uiComponentsRadioGroup_radioButton"
        tabIndex={0}
        role="button"
        aria-pressed={props.isChecked}
      >
        {props.isChecked ? (
          <SelectedIcon color={color} />
        ) : (
          <UnselectedIcon color={color} />
        )}
      </div>
      <div
        className={classNames('uiComponentsRadioGroup_label', labelClassName)}
      >
        {props.children}
      </div>
    </label>
  );
}

export default CustomRadio;
