import React from 'react';
import { Stack, useRadioGroup } from '@chakra-ui/react';
import { RadioGroupProps } from './RadioGroupTypes';
import CustomRadio from './CustomRadio';
import './RadioGroup.scss';

export function RadioGroup({
  options,
  direction,
  spacing,
  isInvalid,
  labelClassName,
  ...props
}: RadioGroupProps): JSX.Element {
  const { getRootProps, getRadioProps } = useRadioGroup(props);
  const group = getRootProps();

  return (
    <Stack {...group} direction={direction} spacing={spacing}>
      {options.map((option) => {
        const radio = getRadioProps({ ...option, enterKeyHint: 'Enter' });
        return (
          <CustomRadio
            key={option.value}
            {...option}
            {...radio}
            isInvalid={option.isInvalid || isInvalid}
            labelClassName={option.labelClassName ?? labelClassName}
          >
            {option.label}
          </CustomRadio>
        );
      })}
    </Stack>
  );
}
