import React, { useCallback } from 'react';
import { Box, Button, Flex, Stack, Text } from '@contact/ui-volta';
import { useNavigate } from '../Hooks';
import { Constants } from '../Constants';

export const ResetPasswordSuccessPage = () => {
  const navigate = useNavigate();

  const handleSignInClick = useCallback(() => {
    window.location.href = `${window.location.origin}/sign-in`;
  }, []);

  return (
    <Stack alignItems="stretch" spacing="80px" paddingX="24px" paddingY="80px">
      <Flex justifyContent="center">
        <Stack spacing="80px">
          <Stack spacing="40px">
            <Text
              fontSize={{
                base: '18px',
                sm: '24px',
              }}
              fontWeight="bold"
            >
              {Constants.ResetPasswordSuccessTitle}
            </Text>
            <Stack
              fontSize={{
                base: '14px',
                sm: '16px',
              }}
              spacing="8px"
            >
              {Constants.ResetPasswordSuccessDescItems.map((item) => (
                <Text>{item}</Text>
              ))}
            </Stack>
          </Stack>
          <Button variant="primary" onClick={handleSignInClick} width="100%">
            {Constants.SignInButtonLabel}
          </Button>
        </Stack>
      </Flex>
    </Stack>
  );
};
