import { App, AppConfig, Configs } from '@contact/auth-common';
import { useQueryParam } from './queryParam';

export interface Context extends AppConfig {
  app: App;
}

export const useContext = (): Context => {
  const app = (useQueryParam('app') || 'OLS') as App;

  return {
    app,
    ...Configs[app],
  };
};
