import React from 'react';

const SelectedIcon = ({
  color = '#5C3463',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3ZM12 19.2C10.0904 19.2 8.25909 18.4414 6.90883 17.0912C5.55857 15.7409 4.8 13.9096 4.8 12C4.8 10.0904 5.55857 8.25909 6.90883 6.90883C8.25909 5.55857 10.0904 4.8 12 4.8C13.9096 4.8 15.7409 5.55857 17.0912 6.90883C18.4414 8.25909 19.2 10.0904 19.2 12C19.2 13.9096 18.4414 15.7409 17.0912 17.0912C15.7409 18.4414 13.9096 19.2 12 19.2ZM12 7.5C10.8065 7.5 9.66193 7.97411 8.81802 8.81802C7.97411 9.66193 7.5 10.8065 7.5 12C7.5 13.1935 7.97411 14.3381 8.81802 15.182C9.66193 16.0259 10.8065 16.5 12 16.5C13.1935 16.5 14.3381 16.0259 15.182 15.182C16.0259 14.3381 16.5 13.1935 16.5 12C16.5 10.8065 16.0259 9.66193 15.182 8.81802C14.3381 7.97411 13.1935 7.5 12 7.5Z"
      fill={color}
    />
  </svg>
);

export default SelectedIcon;
