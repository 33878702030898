import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useNavigate = () => {
  const history = useHistory();
  return useCallback(
    (pathname: string, search?: string) =>
      history.push({
        pathname,
        search,
      }),
    [history]
  );
};
