import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useResetPassword } from '@contact/data-access-hooks';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@contact/ui-volta';
import { Alerts, HTML, Loading } from '@contact/ui-components';
import { useAlertActions } from '@contact/react';
import { useContext, useNavigate, useQueryParam } from '../Hooks';
import { Constants } from '../Constants';
import { isValidPassword } from '../Utils';

const passwordErrorAlert = {
  content: <HTML html={Constants.ResetPasswordError} />,
  icon: Constants.Alert.icon,
  styles: Constants.Alert.styles,
};
const passwordRepeatErrorAlert = {
  content: Constants.ResetPasswordMatchError,
  icon: Constants.Alert.icon,
  styles: Constants.Alert.styles,
};
const resetPasswordErrorAlert = {
  content: (
    <Text
      dangerouslySetInnerHTML={{
        __html: Constants.ResetPasswordCommonError,
      }}
    ></Text>
  ),
  icon: Constants.Alert.icon,
  styles: Constants.Alert.styles,
};

export const ResetPasswordPage = () => {
  const activationKey = useQueryParam('ActivationKey') as string;
  const requestId = useQueryParam('RequestID') as string;
  const context = useContext();

  const navigate = useNavigate();
  const [addAlert, removeAlert] = useAlertActions();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{
    password: string;
    repeatPassword: string;
  }>({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const password = useRef('');
  password.current = watch('password', '');

  const {
    mutate: resetPassword,
    isLoading: isResetPasswordProcessing,
    isSuccess: isResetPasswordSuccess,
    isError: isResetPasswordError,
  } = useResetPassword('v2', undefined, {
    onSuccess: () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'password_reset_success',
        from: context.app === 'ChangePlan' ? 'change plan' : 'my account',
      });
    },
    onError: () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'password_reset_failed',
        from: context.app === 'ChangePlan' ? 'change plan' : 'my account',
      });
    },
  });

  const submitHandler = useCallback(
    ({ password }: { password: string }) =>
      resetPassword({
        activationKey,
        requestId,
        password,
      }),
    [activationKey, requestId, resetPassword]
  );

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageView',
      virtualPagePath: window.location.pathname,
      virtualPageTitle: 'Reset password page',
    });
  }, []);

  useEffect(() => {
    if (isResetPasswordSuccess) {
      navigate('/reset-password-success');
    }
  }, [isResetPasswordSuccess, navigate]);

  useEffect(() => {
    if (errors.password) {
      addAlert(passwordErrorAlert, true);
    }
    return () => removeAlert(passwordErrorAlert);
  }, [addAlert, errors.password, removeAlert]);

  useEffect(() => {
    if (errors.repeatPassword) {
      addAlert(passwordRepeatErrorAlert, true);
    }
    return () => removeAlert(passwordRepeatErrorAlert);
  }, [addAlert, errors.repeatPassword, removeAlert]);

  useEffect(() => {
    if (isResetPasswordError) {
      addAlert(resetPasswordErrorAlert, true);
    }

    return () => removeAlert(resetPasswordErrorAlert);
  }, [addAlert, isResetPasswordError, removeAlert]);

  if (isResetPasswordProcessing) {
    return <Loading />;
  }

  return (
    <Stack alignItems="stretch" spacing="40px" paddingX="24px" paddingY="80px">
      <Flex justifyContent="center">
        <Text
          fontSize={{
            base: '12px',
            sm: '14px',
          }}
          maxWidth="360px"
        >
          <HTML html={Constants.NewPasswordDesc} />
        </Text>
      </Flex>
      <Flex
        as="form"
        onSubmit={handleSubmit(submitHandler)}
        justifyContent="center"
      >
        <Stack flex={1} spacing="24px" maxWidth="360px">
          <FormControl id="password" isInvalid={!!errors.password}>
            <FormLabel>{Constants.NewPasswordLabel}</FormLabel>
            <Input
              type="password"
              {...register('password', {
                validate: isValidPassword,
              })}
            />
          </FormControl>
          <FormControl id="repeatPassword" isInvalid={!!errors.repeatPassword}>
            <FormLabel>{Constants.RepeatPasswordLabel}</FormLabel>
            <Input
              type="password"
              {...register('repeatPassword', {
                validate: (value) => value === password.current,
              })}
              isDisabled={!isValidPassword(password.current)}
            />
          </FormControl>
          <Alerts />
          <Button variant="primary" type="submit" width="100%">
            {Constants.ResetPasswordButtonLabel}
          </Button>
        </Stack>
      </Flex>
    </Stack>
  );
};
