import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from '@contact/ui-components';
import { useNavigate, useQueryParam } from '../Hooks';

export const AccountSettingsRedirect = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const type = Number(useQueryParam('type'));

  useEffect(() => {
    if (type === 1) {
      navigate('/reset-password', search);
    } else if (type === 3) {
      navigate('/account-activation', search);
    }
  }, [navigate, search, type]);

  return <Loading />;
};
