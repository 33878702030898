import React, { useCallback, useEffect } from 'react';
import { Button, Flex, Stack, Text } from '@contact/ui-volta';
import { CrossIcon, Loading, SelectedTickIcon } from '@contact/ui-components';
import { useAccountActivation } from '@contact/data-access-hooks';
import { useNavigate, useQueryParam } from '../Hooks';

export const AccountActivationPage = () => {
  const activationKey = useQueryParam('ActivationKey') as string;
  const requestId = useQueryParam('RequestID') as string;
  const navigate = useNavigate();

  const {
    mutate: activateAccount,
    isSuccess,
    isLoading,
  } = useAccountActivation('v2');

  const handleSignInClick = useCallback(() => {
    window.location.href = `${window.location.origin}/sign-in`;
  }, []);

  useEffect(() => {
    activateAccount({ activationKey, requestId });
  }, []);

  const renderSuccess = (
    <>
      <SelectedTickIcon
        width={{
          base: '120px',
          sm: '160px',
        }}
        height={{
          base: '120px',
          sm: '160px',
        }}
      />
      <Text
        fontSize={{
          base: '24px',
          sm: '32px',
        }}
        fontWeight="bold"
        textAlign="center"
      >
        Great, your account is activated!
      </Text>
      <Stack>
        <Text
          fontSize={{
            base: '14px',
            sm: '16px',
          }}
          textAlign="center"
        >
          To access your account, click on "Sign In" button.
        </Text>
        <Text
          fontSize={{
            base: '14px',
            sm: '16px',
          }}
          textAlign="center"
        >
          Enter your details and password.
        </Text>
        <Text
          fontSize={{
            base: '14px',
            sm: '16px',
          }}
          textAlign="center"
        >
          Then you'll be good to go!
        </Text>
      </Stack>
    </>
  );

  const renderError = (
    <>
      <CrossIcon
        width={{
          base: '120px',
          sm: '160px',
        }}
        height={{
          base: '120px',
          sm: '160px',
        }}
      />
      <Text
        fontSize={{
          base: '24px',
          sm: '32px',
        }}
        fontWeight="bold"
        textAlign="center"
      >
        Something went wrong!
      </Text>
      <Stack>
        <Text
          fontSize={{
            base: '14px',
            sm: '16px',
          }}
          textAlign="center"
        >
          We were unable to activate the account.
        </Text>
        <Text
          fontSize={{
            base: '14px',
            sm: '16px',
          }}
          textAlign="center"
        >
          Please try again or click the "Sign In" button and try signing in.
        </Text>
      </Stack>
    </>
  );

  return (
    <Stack alignItems="stretch" spacing="80px" paddingX="24px" paddingY="64px">
      <Flex justifyContent="center">
        {isLoading ? (
          <Loading customstyle={{ minHeight: 'unset', minWidth: 'unset' }} />
        ) : (
          <Stack spacing="48px" alignItems="center">
            <Stack spacing="24px" alignItems="center">
              {isSuccess ? renderSuccess : renderError}
            </Stack>
            <Button variant="jumbo" onClick={handleSignInClick}>
              Sign In
            </Button>
          </Stack>
        )}
      </Flex>
    </Stack>
  );
};
