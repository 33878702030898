export const injectGtm = (id) => {
  const isProd = process.env.NODE_ENV === 'production';

  if (!isProd && !process.env.NX_FORCE_INJECT_GTM) {
    return;
  }

  if (document.getElementById('gtmScript')) {
    console.warn(
      'gtm script already injected, you may be loading two primary pages in the same process'
    );
  }

  const gtmUrl = process.env.NX_GTM_URL;

  const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  '${gtmUrl}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window, document, 'script', 'dataLayer', '${id}');`;

  const noscriptIframe = `<iframe src="${gtmUrl}/ns.html?id=${id}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  const script = document.createElement('script');
  script.innerHTML = gtmScript;
  script.id = 'gtmScript';
  document.body.append(script);
  const noScript = document.createElement('noscript');
  noScript.innerHTML = noscriptIframe;
  noScript.id = 'gtmNoScript';
  document.body.prepend(noScript);
};
