import { AllowParametersRegex, App, CookieParametersRegex } from './Configs';

export const removeUnallowedParameters = <T>(params: T, app: App): T => {
  const allowedParameters = Object.keys(params).filter((key) =>
    AllowParametersRegex[app].test(key)
  );
  return Object.fromEntries(
    Object.entries(params).filter(([key]) => allowedParameters.includes(key))
  ) as T;
};

export const removeUnallowedCookieParameters = <T>(params: T, app: App) => {
  const allowedParameters = Object.keys(params).filter((key) =>
    CookieParametersRegex[app].test(key)
  );
  return Object.fromEntries(
    Object.entries(params).filter(([key]) => allowedParameters.includes(key))
  ) as T;
};
