import { Segment } from '@contact/data-access';

export type App = 'OLS' | 'ChangePlan';

export interface AppConfig {
  url: string;
  allowedSegments: Segment[];
}

export const Configs: {
  [key in App]: AppConfig;
} = {
  OLS: {
    url: process.env.NX_OLS_URL as string,
    allowedSegments: ['RESI', 'NON-RESI'],
  },
  ChangePlan: {
    url: process.env.NX_CHANGE_PLAN_URL as string,
    allowedSegments: ['RESI'],
  },
};

export const AllowParametersRegex: { [key in App]: RegExp } = {
  OLS: /^.*$/,
  ChangePlan: /^(token|bpId|baId|premiseId|isCSR|crossSell|planId|promoCode|location|path|utm_.+)$/i,
};

export const CookieParametersRegex: { [key in App]: RegExp } = {
  OLS: /^(token|bpId|baId|premiseId|isCSR)$/i,
  ChangePlan: /^(token|bpId|baId|premiseId|isCSR)$/i,
};
