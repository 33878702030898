export const parameterizedString = (...args: string[]) => {
  const str = args[0];
  const params = args.filter((arg, index) => index !== 0);
  if (!str) return '';
  return str.replace(/%s[0-9]+/g, (matchedStr: string) => {
    const variableIndex = Number(matchedStr.replace('%s', '')) - 1;
    return params[variableIndex];
  });
};

export const joinArrayItemsWithCommaAndLastAnd = (items: string[]) =>
  items.join(', ').replace(/, ([^,]*)$/, ' and $1');

export const isValidPassword = (password: string) =>
  /^(?=.*\d)[^!?](.{7,39})$/.test(password);

export const getUrlParameters = (): { [key: string]: string } => {
  const params: { [key: string]: string } = {};
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};
