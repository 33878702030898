export const Constants = {
  SignInTitle: 'Sign in to My Account',
  UsernameLabel: 'Email',
  PasswordLabel: 'Password',
  ForgotPasswordLabel: 'Forgot password',
  SignInButtonLabel: 'Sign in',
  RegisterTitle: 'Want to register an account?',
  RegisterButtonLabel: 'Register for My Account',
  ResetPasswordTitle: 'Reset password',
  ResetPasswordEmailSentTitle: `We've sent you an email`,
  ResetPasswordEmailSentSubTitle:
    'To reset your password, click on the secure link in the email.',
  ResetPasswordButtonLabel: 'Reset password',
  ResetPasswordDesc:
    'To reset your password, enter the email address you use to login to MyAccount. A link will be sent to this email address which will let you reset your password.',
  NewPasswordDesc:
    'Your password should be between <b>8-40 characters.</b> It needs to be <b>different to your last password</b> and include a <b>number</b>. It also cannot start with <b>"!" or "?".</b>',
  NewPasswordLabel: 'New password',
  RepeatPasswordLabel: 'Repeat password',
  ReturnToHomePageButtonLabel: 'Return to the Home Page',
  ResidentialHomePageUrl: `${process.env.NX_WEBSITE_BASE_URL}/residential`,
  ResetPasswordSuccessTitle: 'Great your new password is set!',
  ResetPasswordSuccessDescItems: [
    'To access your account, click on sign in button.',
    'Enter your details and password.',
    `Then you'll be good to go!`,
  ],
  RedirectToOLSForNotAllowedSegment: true,
  Alert: {
    icon: 'Flag',
    styles: {
      '--alert-text-color': 'var(--contact-core-red)',
      '--alert-icon-color': 'var(--contact-core-red)',
    },
  },
  PasswordLengthError:
    'Password needs to be between 8 and 40 characters in length.',
  ResetPasswordError:
    'Your password should be between 8-40 characters. It needs to be different to your last password and include a number. It also cannot start with "!" or "?".',
  ResetPasswordMatchError:
    'Sorry, your new password and confirmation password did not match. Please check and try again.',
  LoginError: 'Try another username or password.',
  SegmentNotAllowedError: `Sorry, %s1 is only available for %s2 accounts.`,
  EmailInvalidError: 'Please enter a valid email address.',
  EmailRequiredError: 'Email address is required.',
  ResetPasswordCommonError:
    'Oops! Your new password doesn&#39;t meet the above rules or something went wrong. Please try selecting a different password.',
  CommonError: 'Sorry, something went wrong. Please try again later.',
  AppNameMapping: {
    OLS: 'OLS',
    ChangePlan: 'ChangePlan',
  },
  SegmentDescMapping: {
    RESI: 'residential',
    'NON-RESI': 'business',
  },
};
