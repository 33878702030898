import React, { useCallback } from 'react';
import { Button, Flex, Stack, Text } from '@contact/ui-volta';
import { EmailIconWithCircle } from '@contact/ui-components';
import { Constants } from '../Constants';

export const ForgotPasswordSuccessPage = () => {
  const handleHomePageClick = useCallback(() => {
    window.location.href = Constants.ResidentialHomePageUrl;
  }, []);

  return (
    <Stack alignItems="stretch" spacing="80px" paddingX="24px" paddingY="64px">
      <Flex justifyContent="center">
        <Stack spacing="48px" alignItems="center">
          <Stack spacing="24px" alignItems="center">
            <EmailIconWithCircle
              width={{
                base: '120px',
                sm: '160px',
              }}
              height={{
                base: '120px',
                sm: '160px',
              }}
            />
            <Text
              fontSize={{
                base: '24px',
                sm: '32px',
              }}
              fontWeight="bold"
              textAlign="center"
            >
              {Constants.ResetPasswordEmailSentTitle}
            </Text>
            <Text
              fontSize={{
                base: '14px',
                sm: '16px',
              }}
              textAlign="center"
            >
              {Constants.ResetPasswordEmailSentSubTitle}
            </Text>
          </Stack>
          <Button variant="secondary" onClick={handleHomePageClick}>
            {Constants.ReturnToHomePageButtonLabel}
          </Button>
        </Stack>
      </Flex>
    </Stack>
  );
};
